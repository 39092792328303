import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";
import { Workloads } from "@busy-human/dt-library";

export const requestWorkload = httpsCallable<Workloads.WorkloadEffects, string>(functions, "requestWorkload");
export const queueWorkload = httpsCallable<{workloadId: string}, {success: true}>(functions, "queueWorkload");
export const sendVerificationEmailCallable = httpsCallable<{ email: string }, { success: boolean }>(functions, "sendVerificationEmail");
export const verifyEmail = httpsCallable<{code: string}, {success: boolean}>(functions, "verifyEmail");

export interface getPaymentLinkOptions {
    productId: string,
    productVersionId: string
    returnPage?: string,
    trialDays?: number
}

interface getPaymentLinkReturn {
    status: string | null,
    paymentLink: string
}

export const getPaymentLink = httpsCallable<getPaymentLinkOptions, getPaymentLinkReturn>(functions, 'getPaymentLink');

export const getStripeProductsAndVersions = httpsCallable<void, void>(functions, 'getStripeProductsAndVersions');

interface getManagementLinkOptions {
    returnPage?: string
}

interface subscriptionManagementLinkReturn {
    managementLink: string | null
}

export const getManagementLink = httpsCallable<getManagementLinkOptions, subscriptionManagementLinkReturn>(functions, 'getManagementLink');