import { Globals } from '@busy-human/gearbox';
import { initializeApp, FirebaseOptions } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { getFirestore, updateDoc, doc, connectFirestoreEmulator } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { ref } from 'vue';

const firebaseOptions: FirebaseOptions = CONFIG.firebase.config;
const app = initializeApp( firebaseOptions );
export const auth = getAuth(app);
// if(FIREBASE_AUTH_EMULATOR_HOST) {
//     const url = new URL(FIREBASE_AUTH_EMULATOR_HOST);
//     connectAuthEmulator(auth, url.host);
// }
export const firestore = getFirestore(app, "developing-tank-website");
if(FIREBASE_FIRESTORE_EMULATOR_ADDRESS) {
    const url = new URL(`http://${FIREBASE_FIRESTORE_EMULATOR_ADDRESS}`);
    connectFirestoreEmulator(firestore, url.hostname, Number(url.port));
    console.log(`%c[Firebase] Using firestore emulator ${url}`, "color: red");
}
export const functions = getFunctions(app);
if(FIREBASE_FUNCTIONS_EMULATOR_HOST) {
    const url = new URL(`http://${FIREBASE_FUNCTIONS_EMULATOR_HOST}`);
    connectFunctionsEmulator(functions, url.hostname, Number(url.port));
    console.log(`%c[Firebase] Using functions emulator ${url}`, "color: red");
}
export const storage = getStorage(app);
if(FIREBASE_STORAGE_EMULATOR_HOST) {
    const url = new URL(`http://${FIREBASE_STORAGE_EMULATOR_HOST}`);
    connectStorageEmulator(storage, url.hostname, Number(url.port));
    console.log(`%c[Firebase] Using storage emulator ${url}`, "color: red");
}
export const analytics = getAnalytics(app);

Globals.setFirestore(firestore);

export function isFirestoreEmulated() {
    return ref(!!FIREBASE_FIRESTORE_EMULATOR_ADDRESS);
}

console.log("Firebase Initialized");

export { updateDoc, doc };