// await import("core-js/stable");
// await import("regenerator-runtime/runtime");

import "./stylesheets/app.scss";
import "@busy-human/vue-component-library/shaft/shaft.scss";
import "vue-toast-notification/dist/theme-default.css";
import events from "./events";
import { useAuth } from "./store/auth";

APPLICATION_LOADER.setLoadingText("Loading Resources");

const [
    ,
    { 
        // FeatureFlags, 
        HexFormPlugin, 
        initEvents, 
        EventBubblingPlugin, 
        VueModal 
    },
    { GlobalComponentsPlugin },
    { default: app },
    { initRaygun },
    { createPinia },
    { default: router },
    { default: VueGlobalProperties },
    { default: Toast }
] = await Promise.all([
    import("@/util/firebase"),
    import("@busy-human/vue-component-library"),
    import("@/components"),
    import("./app"),
    import("@busy-human/raygun-util"),
    import("pinia"),
    import("./router"),
    import("./util/vue-global"),
    import("vue-toast-notification")
]);

APPLICATION_LOADER.setLoadingText("Initializing");

// FeatureFlags.setFlags(CONFIG.flags);
// FeatureFlags.setFlagsForContext("beta", CONFIG.betaFlags);
// FeatureFlags.setFlagsFromFirebase(new FeatureFlags.FirestoreFeatureFlagSource(firestore), {overrideExisting: true});
// import { installVersionManager } from "./util/version";
initEvents(events);
app.component("VueModal", VueModal);

if(!CONFIG.raygun.apiKey) {
    console.warn("raygun apikey not set");
} else {
    initRaygun(CONFIG.raygun.apiKey, { Vue: app, version: VERSION, environment: ENVIRONMENT});
}

const pinia = createPinia();
app.use(pinia);

// FeatureFlags.install(app, {
//     env: ENVIRONMENT
// });

// installVersionManager(app);
app.use(router);
app.use(GlobalComponentsPlugin);
app.use(EventBubblingPlugin);
app.use(VueGlobalProperties);
app.use(HexFormPlugin);
app.use(Toast);
await useAuth().waitForReady();
app.mount("#application-root");
