/* eslint-disable max-lines-per-function */
import { defineStore } from "pinia";
import { onAuthStateChanged, User, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut as logOut, sendPasswordResetEmail, ParsedToken, getIdTokenResult, sendEmailVerification, reload } from "firebase/auth";
import { auth } from "@/util/firebase";
import { computed, ref } from "vue";
import { FirebaseError } from "@firebase/util";
// import { setRaygunUserEventGenerator, updateRaygunUser } from "@/util/raygun-util";
import { analytics  } from "../../src/util/firebase";
import { logEvent } from "firebase/analytics";
import { sendVerificationEmailCallable } from "@/util/functions";
// import { AuthClaims } from "@busy-human/hxp-library";
// import { FeatureFlags } from "@busy-human/vue-component-library";


const AuthErrorMap: {[code: string]: string} = {
    'auth/invalid-email': "Invalid Email",
    'auth/user-not-found': "User Not Found",
    'auth/wrong-password': "Password Invalid",
    'auth/email-already-in-use': "Email Already In Use",
    "auth/invalid-credential": "Invalid Email or Password"
};


function ConvertAuthError(errorCode: string) {
    // return AuthErrorMap[errorCode] || "Unknown"
    return AuthErrorMap[errorCode] || errorCode;
}

export const useAuth = defineStore('auth', () => {
    const user = ref<User | null>(null);
    const claims = ref<ParsedToken | null>(null);
    const _authready = ref<boolean>(false);
    const authUID = ref<string | null>(null);

    const waitingResolves: (() => void)[] = [];

    const isAdmin = computed(() => !!claims.value?.isAdmin);

    onAuthStateChanged(auth, async userAuth => {
        user.value = userAuth;
        if(userAuth) {
            claims.value = (await getIdTokenResult(userAuth)).claims;
            authUID.value = userAuth.uid;
        } else {
            claims.value = null;
            authUID.value = null;
        }
        _authready.value = true;
        // updateRaygunUser(userAuth);
        console.log("Auth State Changed", user.value !== null ? "Authenticated" : "Unauthenticated");

        // if(isAdmin.value) {
        //     // eslint-disable-next-line func-names
        //     (window as any).setUID = function(uid: string) {
        //         setCurrentUID(uid);
        //     };
        // } else if((window as any).setUID) {delete (window as any).setUID;}

        waitingResolves.forEach(res => res());
        waitingResolves.length = 0;
    });

    async function reloadUser() {
        if(!user.value) return;
        await user.value.reload();
        const res = await getIdTokenResult(user.value, true);

        claims.value = res.claims;
    }

    const waitForReady = () => {
        if(_authready.value) {
            return Promise.resolve();
        }
        else {
            return new Promise<void>(res => {waitingResolves.push(res);});
        }
    };

    const isAuthReady = computed(() => _authready.value);
    const isAuthenticated = computed(() => user.value !== null);
    const emailVerified = computed(() => !!user.value?.emailVerified);

    async function signIn(email: string, password: string) {
        try {
            const creds = await signInWithEmailAndPassword(auth, email, password);
            user.value = creds.user;
            logEvent(analytics, "login",{
                method: "Email"
            });
            return { success: true };
        } catch (e) {
            console.error(e);
            return { success: false, reason: ConvertAuthError((e as FirebaseError).code) };
        }
    }

    async function createUser(email: string, password: string) {
        try {
            const creds = await createUserWithEmailAndPassword(auth, email, password);
            user.value = creds.user;
            logEvent(analytics, "signup",{
                method: "Email"
            });
            return { success: true };
        } catch (e) {
            console.error(e);
            return { success: false, reason: ConvertAuthError((e as FirebaseError).code) };
        }
    }

    function requestVerificationEmail() {
        if(!user.value) throw new Error('No user');
        return sendVerificationEmailCallable();
    }

    function sendPasswordReset(email: string) {
        return sendPasswordResetEmail(auth, email);
    }

    async function signOut() {
        try {
            await logOut(auth);
            logEvent(analytics, "signout");
            localStorage.clear();
        } catch (e) {
            console.error(e);
        }
    }

    async function reloadClaims() {
        const token = await user.value?.getIdTokenResult(true);
        claims.value = token?.claims || null;
    }



    // setRaygunUserEventGenerator((userData) => {
    //     if(userData) {
    //         return {
    //             identifier: userData.uid,
    //             email: userData.email || '',
    //             isAnonymous: false
    //         };
    //     } else {
    //         return {
    //             identifier: '',
    //             email: '',
    //             isAnonymous: true
    //         };
    //     }
    // });

    // const currentUID = computed(() => {
    //     if(proxiedRootUID.value) return proxiedRootUID.value;
    //     if(claims.value?.rootUID) return claims.value.rootUID as string;
    //     if(proxiedUID.value) return proxiedUID.value;
    //     else return authUID.value;
    // });

    return { user, isAuthReady, isAuthenticated, requestVerificationEmail, emailVerified, signIn, createUser, signOut, sendPasswordReset, reloadClaims, reloadUser, claims, isAdmin, authUID, waitForReady };
});